.GlobalBanner {
  position: sticky;
  left: 0;
  top: 0;
  right: 0;
  margin-bottom: 0;
  box-shadow: 0px -1px 8px rgba(0, 0, 0, 0.5);
  text-align: center;
  z-index: 1000;

  @media (max-width: 576px) {
    position: static;
  }
}
