@import "./theme.scss";

$main-font: neue-haas-unica, sans-serif;
$secondary-font: kepler-std-display, serif;

*,
*:before,
*:after {
  box-sizing: border-box;
}

html, body {
  -webkit-text-size-adjust: 100%; /* Prevent font scaling in iOS */
}

html.calendly-popup-open, body.calendly-popup-open {
  overflow: hidden;
  position: fixed;
  width: 100%;
}

body {
  color: $body;
  font-family: $main-font;
  margin-top: 0;

  font-size: 16px;
  font-variant: tabular-nums;
  line-height: 1.5em;
  font-feature-settings: "tnum", "tnum";

  img {
    vertical-align: middle;
    border-style: none;
  }
}

div {
  a {
    text-decoration: none;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    transition: color 0.3s;
    -webkit-text-decoration-skip: objects;
  }
}

p {
  font-family: $main-font;
  color: $body;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 175%;
  letter-spacing: normal;
  margin-top: 0;

  a {
    color: $navy;
    text-decoration: underline;
    margin-top: 0;
  }

  @media (max-width: $small) {
    font-size: 16px;
  }
}

h1 {
  font-family: $main-font;
  font-weight: 500;
  font-size: 68px;
  line-height: 110%;
  letter-spacing: -2px;
  color: $header;

  span {
    font-family: $secondary-font;
    font-style: italic;
    font-size: 1.03em;
    letter-spacing: -1px;
  }

  @media (max-width: $small) {
    font-size: 52px;
    letter-spacing: -1.04px;
  }
}

h2 {
  font-family: $main-font;
  font-weight: 500;
  font-size: 44px;
  line-height: 110%;
  letter-spacing: -1px;
  color: $header;

  span {
    font-family: $secondary-font;
    font-style: italic;
    font-size: 1.03em;
    letter-spacing: 0;
  }

  @media (max-width: $small) {
    font-size: 38px;
    letter-spacing: -0.38px;
  }
}

h3 {
  font-family: $main-font;
  font-weight: 500;
  font-size: 36px;
  line-height: 110%;
  letter-spacing: -1px;
  color: $header;

  @media (max-width: $small) {
    font-size: 28px;
  }
}

h4 {
  font-family: $main-font;
  font-weight: 500;
  font-size: 28px;
  line-height: 110%;
  letter-spacing: 0px;
  color: $header;

  @media (max-width: $small) {
    font-size: 22px;
  }
}

h5 {
  font-family: $main-font;
  font-weight: 500;
  font-size: 24px;
  line-height: 110%;
  letter-spacing: 0px;
  color: $header;

  @media (max-width: $small) {
    font-size: 18px;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5em;
  font-weight: 500;
}
