@import "../../../styles/theme.scss";

.nftBanner {
  width: 100%;
  background-color: #404042;
  font-size: 18px;
  color: white;
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
  justify-content: center;

  &__flexColumn {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;

    background: url(/images/nft-vote-background.jpg);
    background-repeat: no-repeat;
    background-position: top;
    background-color: #0f0d10;
    background-size: 100% auto;

    &:hover {
      background-color: #4d4d4f;
    }
  }

  &__container {
    max-width: 1440px;
    width: 100%;
    padding: 20px 50px;
    position: relative;

    width: 100%;

    // background: url("/images/tiicker-seal-fully-transparent.png");
    // background-repeat: no-repeat;
    // background-position: center;
  }

  &__content {
    cursor: pointer;
    margin: 0;
    display: flex;
    justify-content: center;

    @media (max-width: $small) {
      flex-direction: column;
    }
  }

  &__seal {
    // background: url("/images/tiicker-seal-fully-transparent.png");
    // background-repeat: no-repeat;
    // background-size: 100%;
    // background-position: center;
    // width: 720px;
    // height: 700px;
    max-width: 100%;
    // margin: -100px auto -150px;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: $medium) {
      height: 600px;
      margin: -100px auto -100px;
    }

    @media (max-width: $medium) {
      height: 600px;
      margin: -160px auto;
    }
  }

  &__title {
    color: #caac7d;
    margin-right: 50px;
    font-family: "DM Serif Display", serif;

    @media (max-width: $small) {
      margin-right: 0;
    }
  }

  &__text {
    font-size: 20px;
    color: white;
    font-family: "DM Serif Display", serif;
  }

  &__x {
    display: flex;
    justify-content: center;
    align-items: center;

    position: absolute;
    right: 30px;
    top: 50%;
    margin-top: -15px;

    height: 30px;
    width: 30px;
    background-color: #404042;
    border-radius: 50%;
    cursor: pointer;
    font-family: "DM Serif Display", serif;
    z-index: 10;

    color: white;

    &:hover {
      color: #999;
    }
  }

  &__link {
    color: white;
    font-size: 40px;
    margin-left: 25px;
    // position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 10px;
    height: 30px;
    width: 30px;
    cursor: pointer;
    font-family: "DM Serif Display", serif;
    // &:hover {
    //   color: @sage;
    // }
  }
}
