@import "../../styles/theme.scss";

.DevelopmentGatekeeper {
  &__swalButtons {
    &__confirm {
      background-color: $tiicker-green;
      color: $navy;
      border: none;
      border-radius: 4px;
      padding: 12px 24px;
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: center;
      cursor: pointer;
      margin-right: 16px;
    }

    &__confirm:hover {
      background-color: #fff;
      border: 1px solid $tiicker-green;
    }
  }
}
