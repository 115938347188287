@import "../../styles/theme.scss";

.formField {
  input::placeholder {
    // color: @primary-4;
  }
}

.inputWrapper {
  width: 100%;

  // TODO: This is bad, figure out a way to fix this.
  // Ant design is overriding the number input's width from
  // 100% to 90px.. not sure where it's coming from
  & > div {
    width: 100% !important;
  }

  .formDate {
    padding: 6.5px 5px;

    input {
      color: #707070;
      font-size: 14px;
    }
  }
}

.formSelect {
  &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 36px;
    border-color: #c5c5c5;
  }

  .ant-select-selection-search-input {
    height: 36px !important;
  }

  .ant-select-selection-item {
    line-height: 36px !important;
    font-weight: 400;
  }
}

.input,
.inputWrapper .input {
  color: #707070;
  width: 100%;
  font-size: 14px;
  border-radius: 2px;
  border: solid 1px #c5c5c5;
  padding: 5px;

  &:focus {
    color: #323232;
    outline-color: $tiicker-green;
  }

  &Inline {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    margin-bottom: 10px;

    .labelContainer {
      margin-bottom: 0;
    }

    .labelContainer,
    .inputWrapper {
      width: 50%;
    }

    .error {
      width: 50%;
      margin-left: 50%;
    }
  }
}

.error {
  color: rgb(211, 44, 44);
  font-size: 12px;
}

.label,
.label span,
.label label {
  font-size: 14px;
  color: #414141;
  font-weight: 400;

  display: inline-flex;
  align-items: center;
}

.required:before {
  content: "* ";
  color: $tiicker-green;
}

.labelContainer {
  margin-bottom: 8px;
}
