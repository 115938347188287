@import "../../styles/theme.scss";

.brokerageConnectionErrorButtonsContainer {
  display: flex;
  justify-content: center;

  .errorButton {
    margin-left: 5px;
    margin-right: 5px;
    // width: 60px;
  }

  .submitButton {
    margin-top: 15px;
  }
}

.plaidErrorMessageTitle {
  color: $tiicker-green;
}
