.ImpersonationBanner {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background-color: green;
  font-size: 12px;
  color: white;
  padding: 0 10px;

  a {
    color: white;
    text-decoration: underline;
  }
}
