@import "../../../styles/theme.scss";

.Button {
  display: inline-flex;
  background-color: $highlight;
  color: $secondary;
  font-weight: 500;
  cursor: pointer;
  position: relative;
  padding: 16.5px 28.5px;
  border: 1.5px solid transparent;
  text-decoration: none;
  min-height: 48.5px;
  transition: 0.3s;

  &:hover {
    color: $secondary;
  }

  &_Primary {
    border: 1.5px solid $highlight;

    &:hover:not(&_Arrow) {
      background-color: $pale-mint;
      transition: background-color 0.3s;
    }
  }

  &_Primary.Button_Arrow:hover {
    background-color: $highlight;
  }

  &_Disabled {
    cursor: no-drop;
    background-color: rgba(87, 90, 96, 0.1) !important;
    color: $grey;
    opacity: 0.5;
  }

  &_Small {
    padding: 8.5px 18.5px;
    font-size: 13px;
    min-height: 36px;
  }

  ////////////////////////
  // Size Configuration
  ////////////////////////
  &_Medium {
    padding: 11.7px 18.5px;
    font-size: 14px;
    line-height: 1.2;
  }
  ////////////////////////
  // --/ End Size Configuration
  ////////////////////////

  ////////////////////////
  // Secondary Configuration
  ////////////////////////

  &_Secondary {
    border: 1.5px solid $green-highlight;
    background-color: transparent;
    transition: background-color 0.3s;

    &:hover {
      background-color: $green-highlight;
      color: $secondary;
    }
  }

  ////////////////////////
  // --/ End Secondary Configuration
  ////////////////////////

  ////////////////////////
  // Inline Configuration
  ////////////////////////

  &_Inline {
    background-color: transparent;
    padding: 16.5px 0;
  }

  &_Inline &__Wrapper_Arrow {
    &:before,
    &:after {
      background-image: url("/icons/right-arrow-green.svg");
    }
  }

  ////////////////////////
  // --/ End Inline Configuration
  ////////////////////////

  ////////////////////////
  // Arrow and Hover Configuration
  ////////////////////////

  // Hover at main element so all surface triggers
  // hover sliding effect
  &:hover &__Wrapper_Arrow:not(&__Wrapper_NoAnimate) {
    margin-left: 26px;
    margin-right: 0px;

    &:after {
      opacity: 0;
    }
    &:before {
      opacity: 1;
    }
  }

  &__Wrapper {
    &_Arrow {
      display: inline-flex;
      margin-right: 26px;
      position: relative;
      transition: margin-right 0.3s, margin-left 0.3s;

      &:after,
      &:before {
        content: "";
        pointer-events: none;

        position: absolute;
        top: calc(50% - 4.5px);

        height: 9px;
        width: 17px;

        background-image: url("/icons/right-arrow-blue.svg");
        background-repeat: none;
        background-size: 100%;

        transition: opacity 0.3s;
      }

      &:after {
        left: calc(100% + 10px);
      }
      &:before {
        right: calc(100% + 10px);
        opacity: 0;
      }
    }
  }
  ////////////////////////
  // --/ End Arrow and Hover Configuration
  ////////////////////////
}
