@import "/styles/theme.scss";

.TiiDisplay {
  display: inline-flex;
  align-items: baseline;

  &__textDark {
    color: #353840;
    margin-bottom: 0;
  }

  &__textLight {
    color: white;
    margin-bottom: 0;
  }

  &__textGold {
    color: #8a7033;
  }

  .thin_xs &__image {
    height: 10px;
    margin-right: 5px;
  }

  .thin_xs &__textDark,
  .thin_xs &__textGold,
  .thin_xs &__textLight {
    font-size: 12.5px;
    font-weight: 300;
    line-height: 1.1;
    letter-spacing: 1.25px;
  }

  .xs &__image {
    height: 13px;
    margin-right: 1px;
  }

  .xs &__textDark,
  .xs &__textGold,
  .xs &__textLight {
    font-size: 16px;
    font-weight: 700;
    line-height: 13px;
    margin-bottom: 0;
  }

  .sm &__image {
    height: 16px;
    margin-right: 3px;
  }

  .sm &__textDark,
  .sm &__textGold,
  .sm &__textLight {
    font-size: 22px;
    font-weight: 700;
    line-height: 16px;
  }

  .mdsm &__image {
    height: 16px;
    margin-right: 3px;
  }

  .mdsm &__textDark,
  .mdsm &__textGold,
  .mdsm &__textLight {
    font-size: 22px;
    font-weight: 700;
    line-height: 16px;
  }

  .md &__image {
    height: 25px;
    margin-right: 3px;
  }

  .md &__textDark,
  .md &__textGold,
  .md &__textLight {
    font-weight: 700;
    font-size: 34px;
    line-height: 25px;
  }

  .mdlg &__image {
    height: 35px;
    margin-right: 3px;

    @media (max-width: $small) {
      height: 20px;
    }

    &__remain_size {
      height: 35px;
    }
  }

  .mdlg &__textDark,
  .mdlg &__textGold,
  .mdlg &__textLight {
    font-weight: 500;
    font-size: 48px;
    line-height: 35px;
    padding-bottom: 10px;

    @media (max-width: $small) {
      font-size: 28px;
      line-height: 20px;
      padding-bottom: 0;
    }

    &__remain_size {
      font-size: 48px;
      line-height: 35px;
      padding-bottom: 10px;
    }
  }

  .lg &__image {
    height: 45px;
    margin-right: 3px;
  }

  .lg &__textDark,
  .lg &__textGold,
  .lg &__textLight {
    font-weight: 700;
    font-size: 62px;
    line-height: 45px;
  }
}
