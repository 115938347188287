@import "../../styles/theme.scss";

.Banner {
  width: 100%;
  background-color: #112243;
  background-image: url("/images/patterned-banner.svg");
  background-size: cover;
  font-size: 18px;
  color: white;
  display: flex;
  justify-content: center;

  &__close {
    position: absolute;
    cursor: pointer;
    color: white;
    right: 30px;
    top: calc(50% - 10px);
  }

  &__container {
    max-width: 1440px;
    width: 100%;
    position: relative;
  }

  &__content {
    margin: 0;
    padding: 16px 50px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  &__title {
    font-weight: 400;
    font-size: 20px;
    line-height: 22px;
    color: white;
    margin-right: 10px;
    margin-bottom: 0;
    letter-spacing: 0.5px;
  }

  &__text {
    color: white;
    font-size: 14px;
    line-height: 24.5px;

    &__qa {
      font-size: 24px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.2;
      letter-spacing: normal;
      text-align: left;
      color: white;

      @media (max-width: $small) {
        font-size: 14px;
        margin-bottom: 8px;
      }
    }
  }

  &__link {
    text-decoration: underline;
    padding-left: 10px;
    color: white;
    font-size: 14px;
    line-height: 24.5px;

    &__qa {
      font-size: 24px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.2;
      letter-spacing: normal;
      text-align: left;
      color: white;
      margin-left: 16px;

      @media (max-width: $small) {
        font-size: 14px;
      }
    }
  }

  &__icon {
    margin-left: 16px;
    color: $tiicker-green;
  }
}
