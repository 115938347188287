@import "../../styles/theme.scss";

.parent {
  position: fixed;
  // left: 20px;
  // height: 400px;
  height: 100%;
  top: 20px;
  bottom: 0;
  left: 0;
  right: 20px;
  z-index: 500;
  width: 100%;
  display: flex;
  justify-content: right;

  @media (max-height: 600px) {
    top: 20px;
  }
}

$notificationMaxWidth: 450px;

.notification {
  background-color: white;
  border: 1px solid $tiicker-green;
  border-radius: 8px;
  position: relative;
  height: min-content;
  z-index: 5;
  margin-right: 20px;
  padding: 20px;
  padding-top: 12px;
  max-width: 450px;

  animation-name: slidein;
  animation-duration: 0.3s;
  right: 0;

  @media (max-width: $small) {
    max-width: calc(100% - 40px);
  }
}

@keyframes slidein {
  from {
    right: calc(($notificationMaxWidth * -1) - 20px);
    opacity: 0;

    @media (max-width: $small) {
      right: 100%;
    }
  }
}

.closing {
  animation-duration: 0.3s;
  animation-name: slideout;
}

@keyframes slideout {
  to {
    right: calc(($notificationMaxWidth * -1) - 20px);
    opacity: 0;

    @media (max-width: $small) {
      right: -100%;
    }
  }
}

.closeContainer {
  text-align: right;
  display: flex;
  justify-content: right;
}

.closeButtonContainer {
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
